import { createClient } from "@supabase/supabase-js"
const supabaseUrl = "https://mswpyiazzzbahmzaydlw.supabase.co"
const supabaseKey =
	"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1zd3B5aWF6enpiYWhtemF5ZGx3Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzQ1ODAzMzAsImV4cCI6MTk5MDE1NjMzMH0._lD4WEN1a6ZE8U9S8uTGR2INIJByqZyUrfXB2qjY548"

const supabase = createClient(supabaseUrl, supabaseKey)
window.supabase = supabase

/*
export async function supabaseFetch() {
	const store = Alpine.store("refueat")

	const date = document.querySelector("#date").value

	// processes table
	if (date) {
		let dateobj = new Date(date)
		let dayAfter = dateobj.setDate(dateobj.getDate() + 1)
		const dayAfterDate = new Date(dayAfter).toISOString().slice(0, 10)

		const { data, error } = await supabase.from("processes").select().gte("date", date).lt("date", dayAfterDate)

		if (error) console.error(error)

		if (data) {
			store.currentProcesses = []
			store.finishedProcesses = []
			for (const process of data) {
				if (!process.done && !process.aborted) {
					store.currentProcesses.push(process)
				}
				if (process.done) {
					store.finishedProcesses.push(process)
				}
			}
			console.log(store.currentProcesses)
			console.log(store.finishedProcesses)
		}
	}
}

export async function supabaseRealtimeInit() {
	const store = Alpine.store("refueat")

	console.log("init realtime")
	const channel = _supabase
		.channel("db-changes")
		.on(
			"postgres_changes",
			{
				event: "*",
				schema: "public",
				table: "processes",
			},
			(payload) => displayNewProcess(payload)
		)
		.subscribe()
}

function displayNewProcess(payload) {
	const store = Alpine.store("refueat")

	// payload contains object, object.new is updated / inserted row
	// console.log(payload)

	// things to update:

	// currentProcesses: [],
	// -------> directly

	// preparingCells: [],
	// fullyPreparingRowsTotalCells: [],
	// preparingWorkers
	// -------> via displayCurrentProcesses()

	// finishedProcesses: [],
	// -------> directly

	// finishedCells: [],
	// fullyFinishedRowsTotalCells: [],
	// -------> via displayFinishedProcesses()

	let process = payload.new

	const date = document.querySelector("#date").value
	// only do action, if we are looking at the date of interest
	if (date && process.date == date) {
		if (payload.eventType == "INSERT") {
			// new entry - is never done nor aborted
			store.currentProcesses.push(process)
		} else if (payload.eventType == "UPDATE") {
			// aborted or done entry
			// remove from current processes
			let processIdx = store.currentProcesses.findIndex((p) => p.id == process.id)
			store.currentProcesses.splice(processIdx, 1)

			// set worker status to not preparing
			let worker = store.workers.find((w) => w.id == process.employee)
			worker.isPreparing = false
			worker.preparingCells = []
			worker.preparingProductsOrdersQuantities = {}

			// add to finished processes, if done
			if (process.done) {
				store.finishedProcesses.push(process)
			}
		}

		// display if the table is currently shown, aka orders/products etc have been fetched
		if (store.showTable) {
			displayCurrentProcesses()
			if (process.done) displayfinishedProcesses()
		}
	}
}
*/
