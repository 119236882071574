import { registerSW } from "virtual:pwa-register"
registerSW({ immediate: true })

// check for update every 30 seconds, will trigger reload
setInterval(async () => {
	navigator.serviceWorker.getRegistrations().then(function (registrations) {
		for (let registration of registrations) {
			registration.update()
		}
	})
}, 30 * 1000)

import "./style.scss"
import Alpine from "alpinejs"

window.Alpine = Alpine

import "./realtime.js"
import "./alpineInit.js"
import "./fetchOrders.js"
import "./interaction.js"

console.log("loaded main")

Alpine.start()
