/******************* DATE *******************************/

export function changeWeek(dir) {
	const store = Alpine.store("refueat")

	let dateObj = new Date(store.daysOfTheWeek[1][2]) // take current week's TUESDAY because of some weird bug!!

	let newDateISO

	if (dir == "prev") {
		dateObj.setDate(dateObj.getDate() - 7)
		newDateISO = dateObj.toISOString().slice(0, 10)
		console.log(newDateISO)
	} else {
		console.log(dateObj.getDate())
		dateObj.setDate(dateObj.getDate() + 7)
		newDateISO = dateObj.toISOString().slice(0, 10)
		console.log(newDateISO)
	}

	document.getElementById("date").value = newDateISO
	store.curWeek = dateObj.getWeek()
	// processOrders() // called from event listener!
}

Date.prototype.getWeek = function () {
	var onejan = new Date(this.getFullYear(), 0, 1)
	var today = new Date(this.getFullYear(), this.getMonth(), this.getDate())
	var dayOfYear = (today - onejan + 86400000) / 86400000
	return Math.ceil((dayOfYear - 1) / 7)
}
