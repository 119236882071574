// import { supabaseFetch, supabaseRealtimeInit } from "./realtime"
import { processOrders } from "./fetchOrders"
import { changeWeek } from "./interaction"

/* concepts:
 *
 * orderData {
 * 			"date":
 * 		[
 * 			{
 * 			company: str,
 * 			customerName: str,
 * 			customerNote: str,
 * 			distance: str,
 * 			menus: {
 * 				"productShorthand":  { "sizes": qtys, ...},
 * 			},
 * 			orderNumber: str,
 * 			paymentMethod: str,
 * 			phone: str,
 * 			shippingAddress: str,
 * 			shippingMethod: str,
 * 			time: str,
 * 			total: str,
 * 			},
 * 			{...}
 * 		],
 * 			"date": [...],
 * }
 *
 * productQuantities {
 * 		"productShorthands": {
 * 			orders: {
 * 				"orderIds": {"sizes": qtys, ...},
 * 			},
 * 			totalQtys: {"sizes": qtys, ...},
 * 		}
 * }
 *
 * sortedProductQuantities [
 * 		["productShorthands", {
 * 			orders: {
 * 				"orderIds": {"sizes": qtys, ...},
 * 			},
 * 			totalQtys: {"sizes": qtys, ...},
 * 		}], ["", {}], ...
 * ]
 *
 * productComponents {
 * 		"productIds": {
 * 			components: {
 * 				"0": {component: str, qty/grams/liters: str},
 * 			}
 * 		}
 * }
 *
 * nameIds {
 * 		"productShorthands": [
 * 			["productId", "productSize"],
 * 			[123, "XL"],
 * 		]
 * }
 *
 */

document.addEventListener("alpine:init", () => {
	const store = {
		lastModified: "",
		showTable: false,
		loading: false,
		curDate: "",
		curFilter: "alle",
		curWeek: new Date().getWeek(),
		daysOfTheWeek: [
			["Montag", "", ""],
			["Dienstag", "", ""],
			["Mittwoch", "", ""],
			["Donnerstag", "", ""],
			["Freitag", "", ""],
			["Samstag", "", ""],
			["Sonntag", "", ""],
		],
		orderData: {}, // object that contains orders infos like id, time rerferenced by order_date
		fetchedVariations: [], // array caching all fetched product variations in a session
		productQuantities: {}, // object that contains products and respective quantities per order and totals
		// sortedProductQuantities: [], // array that contains same information as the object above but sorted
		componentPosts: [], // array that contains the bestandteil posts from backend
		productComponents: {}, // object that contains products components (fetched)
		dateComponents: {},
		componentTotals: {}, // object containing totals per showed week
		nameIds: {}, // object that contains all relevant productIds for a shorthand defined in a _product_table
		// activeComponents: "", // string to display in the tooltip
		// clickedProductShorthand: "", // name of the product whose components are shown
		// clickedCells: [],
		// fullyClickedRowsTotalCells: [],
		clickedComponent: "",
		// finishedCells: [],
		// finishedProcesses: [], // array keeping track of all finished processes
		components: [], // array that contains the names of the components of interest
	}
	const lastModSplit = document.lastModified.split(" ")
	const d = lastModSplit[0].split("/")
	const t = lastModSplit[1].split(":")

	store.lastModified = `${d[1]}.${d[0]}.${d[2]} ${t[0]}:${t[1]}`
	Alpine.store("refueat", store)

	// default date to today
	let today = new Date()
	let isoToday = today.toISOString().slice(0, 10)
	document.getElementById("date").value = isoToday

	// init other stuff and listeners
	// supabaseFetch()
	// supabaseRealtimeInit()
	processOrders()
	// wcRealtimeInit() // call this after first order fetch

	document.querySelector("#date").addEventListener("change", function () {
		// supabaseFetch()
		let newWeek = new Date(document.getElementById("date").value).getWeek()
		if (Alpine.store("refueat").curWeek == newWeek) return
		Alpine.store("refueat").curWeek = newWeek
		processOrders()
	})

	document.querySelector(".kw .left").addEventListener("click", function () {
		changeWeek("prev")
		processOrders()
	})

	document.querySelector(".kw .right").addEventListener("click", function () {
		changeWeek("next")
		processOrders()
	})
})
