const isDeployed = window.origin == "https://production.fs.refueat.de"

const ck = "ck_bb0e4669f8fe498b3cae598c3c1037bdd72f0b28" // live
const cs = "cs_48290dd472dfd6a059d87e172e8812a3fc72741d" // live
// const ck = "ck_9c4a246fe6d539511bd67c4667601351a87c220f"; // dev
// const cs = "cs_7949881f5260928571424ae03a2d3c2708dd45e4"; // dev

let website = "https://refueat.de/"
// let website = "https://dev.refueat.de/";
if (isDeployed) website = "https://refueat.de/"

let days = []

export async function processOrders() {
	const store = Alpine.store("refueat")

	let date = document.getElementById("date").value
	if (!date) {
		alert("Bitte Datum auswählen!")
		return
	}
	const german_date = date.substring(8, 10) + "." + date.substring(5, 7) + "." + date.substring(0, 4)
	document.querySelector(".print-date").innerHTML = "- " + german_date

	// if we changed date (or its the first fetch) we reset everything
	if (store.curDate != date) {
		store.showTable = false
		store.loading = true
		// store.orderData = {}
		store.productQuantities = {}
		store.productMetas = {}
		store.curDate = date

		let dateObj = new Date(date) // getDay from 0 (sun) to 6 (sat)

		let day = dateObj.getDay()
		if (day == 0) day = 7

		dateObj.setDate(dateObj.getDate() - day) // sets date to sunday before the week, we first add monday in for loop
		days = []
		for (let i = 0; i < 7; i++) {
			let newDateObj = new Date(dateObj.setDate(dateObj.getDate() + 1))
			store.daysOfTheWeek[i][1] = newDateObj.toLocaleDateString("de-DE", {
				day: "2-digit",
				month: "2-digit",
				year: "numeric",
			})
			let iso = newDateObj.toISOString().slice(0, 10)
			days.push(iso)
			store.daysOfTheWeek[i][2] = iso
		}
	} else if (!store.orderData.length) {
		store.showTable = false
		store.loading = true
	}

	// fetch the bestandteile from backend ONCE - static
	if (!store.componentPosts.length) {
		const url = website + "/wp-json/wp/v2/bestandteil/?per_page=100"
		await fetch(url, { method: "GET" })
			.then((response) => response.json())
			.then((response) => {
				store.componentPosts = response
			})
	}

	let url =
		website +
		"/wp-json/wc/v3/orders?consumer_key=" +
		ck +
		"&consumer_secret=" +
		cs +
		"&status=processing,completed&per_page=100&order_date="
	for (const day of days) {
		url += day + ","
	}
	url = url.substring(0, url.length - 1)

	fetch(url, { method: "GET" })
		.then((response) => response.json())
		.then((response) => transformJsonToAlpine(response))
		.then((response) => {
			setupProductsAndTotals(response)
			setupComponents()
			store.loading = false
			store.showTable = true
			// hacky timeout to avoid the function being called when table isnt fully visible yet
			// setTimeout(() => {
			// 	displayCurrentProcesses()
			// 	displayfinishedProcesses()
			// }, 20)
			if (!wcInterval) {
				wcRealtimeInit()
			}
		})
		.catch((err) => console.error(err))
}

async function transformJsonToAlpine(data) {
	const store = Alpine.store("refueat")

	// loop through fetched orders
	for (const id in data) {
		const order = data[id]

		// find day and corresponding orderData array
		let date
		if (order.delivery_type == "delivery") {
			date = order.meta_data.find((m) => m.key == "delivery_date").value
		} else {
			date = order.meta_data.find((m) => m.key == "pickup_date").value
		}
		let orderData
		if (store.orderData[date]) orderData = store.orderData[date]
		else {
			store.orderData[date] = []
			orderData = store.orderData[date]
		}

		const orderNumber = order.number
		const time = getTimeFromOrder(order)
		const shippingMethod = getDeliveryNameFromOrder(order)
		const paymentMethod = order.payment_method_title

		// if the order information is already present, we dont need to add it again
		let existingOrder = orderData.find((o) => o.id == order.id)
		if (!existingOrder) {
			orderData.push({})
			let orderEntry = orderData[orderData.length - 1]
			orderEntry.menus = {}

			let fetches = []

			// loop through items within one order
			for (let j = 0; j < order.line_items.length; j++) {
				const product = order.line_items[j]

				// check the referenced products by fetching them from woocommerce and looking at their product_table
				if (product.referenced_products_table) {
					const referenced_products_table = JSON.parse(
						product.referenced_products_table.replaceAll("&quot;", `"`)
					)
					const productId = product.product_id
					for (const key in referenced_products_table) {
						const variation = referenced_products_table[key]
						const variationId = variation.product
						const variationQty = variation.qty
						// $product_id = wp_get_post_parent_id($variation_id);

						// this is actually the wrong productId, idk why it still fetches correctly
						// it is the id of the product, that references another (i.e. the id of a catering), NOT the referenced id
						const url =
							website +
							"/wp-json/wc/v3/products/" +
							productId +
							"/variations/" +
							variationId +
							"?consumer_key=" +
							ck +
							"&consumer_secret=" +
							cs

						let found = store.fetchedVariations.find((v) => v.vId == variationId)
						if (!found) {
							fetches.push(
								fetch(url, { method: "GET" })
									.then((response) => response.json())
									.then((response) => {
										store.fetchedVariations.push({
											vId: variationId,
											responseJson: response,
										})
										let productTable = response.meta_data
											? response.meta_data.find((item) => item.key === "_product_table")
											: null
										if (productTable) {
											processProductTable(
												response.id,
												productTable.value,
												product,
												orderEntry,
												variationQty
											)
										} else {
											// TODO?: handle error / unfinished data structure
											console.error(
												"FEHLER! Prüfe die referenzierten Produkte im Produkt '" +
													product.name +
													"'"
											)
											console.error("product_id: " + product.product_id)
											console.error("variation_id: " + product.variation_id)
											console.error(url)
										}
										let components = response.meta_data
											? response.meta_data.find(
													(item) => item.key === "_product_components_table"
											  )
											: null
										if (components) {
											components = JSON.parse(components.value.replaceAll(`\\\"`, `"`))
											if (!store.productComponents[variationId]) {
												components = Object.values(components)

												for (const component of components) {
													let slug = component.component
													let post = store.componentPosts.find((p) => p.slug == slug)
													let name = post ? post.title.rendered : slug // change to accomodate for outdated tables in backend
													component.component = name
												}

												let obj = {
													components: components,
												}
												store.productComponents[variationId] = obj
											}
										}
									})
									.catch((err) => console.error(err))
							)
						} else {
							let productTable = found.responseJson.meta_data
								? found.responseJson.meta_data.find((item) => item.key === "_product_table")
								: null
							if (productTable) {
								processProductTable(
									found.responseJson.id,
									productTable.value,
									product,
									orderEntry,
									variationQty
								)
							} else {
								// TODO?: handle error / unfinished data structure
								console.error(
									"FEHLER! Prüfe die referenzierten Produkte im Produkt '" + product.name + "'"
								)
								console.error("product_id: " + product.product_id)
								console.error("variation_id: " + product.variation_id)
								console.error(url)
							}
						}
					}
				} else if (product.product_table) {
					try {
						const id = product.variation_id ? product.variation_id : product.product_id
						processProductTable(id, product.product_table, product, orderEntry)
					} catch (error) {
						console.log(product)
						console.log(error)
					}
					// else case might become irrelevant once product structure in backend is complete
				} else {
					// const id = product.variation_id ? product.variation_id : product.product_id
					const shorthand = product.parent_name ? product.parent_name : product.name
					orderEntry.menus[shorthand] = product.quantity
				}
				if (product.product_components_table) {
					let components = JSON.parse(product.product_components_table.replaceAll("&quot;", `"`))
					components = Object.values(components)

					for (const component of components) {
						let slug = component.component
						let post = store.componentPosts.find((p) => p.slug == slug)
						let name = post ? post.title.rendered : slug // change to accomodate for outdated tables in backend
						component.component = name
					}

					let obj = {
						components: components,
					}
					const id = product.variation_id ? product.variation_id : product.product_id
					store.productComponents[id] = obj
				}
			}

			// if we didnt fetch we dont wait here
			let fetchingPromise = Promise.all(fetches).then(async function () {
				// save order information

				orderEntry.id = order.id

				const s = order.shipping
				const b = order.billing
				orderEntry.shippingAddress = `${s.address_1} <br> ${s.postcode} ${s.city}`
				orderEntry.company = s.company || ""

				orderEntry.phone = order.billing.phone || ""
				orderEntry.customerName = (s.first_name || "") + " " + (s.last_name || "")
				if (orderEntry.customerName == " ")
					orderEntry.customerName = (b.first_name || "") + " " + (b.last_name || "") // see if name is in billing instead
				orderEntry.customerNote = order.customer_note || ""
				orderEntry.orderNumber = orderNumber
				orderEntry.time = time
				orderEntry.shippingMethod = shippingMethod
				orderEntry.paymentMethod = paymentMethod
				orderEntry.total = order.total.replace(".", ",") + order.currency_symbol
				orderEntry.distance = ""
				orderEntry.createdAt = order.date_created_gmt

				// added false to save requests to cloud
				// undo once a smart method is figured out TODO
				// if (false && shippingMethod.includes("Lieferung")) {
				// 	const origin = "Bautzener Str. 40, 10829 Berlin"

				// 	var directionsService = new google.maps.DirectionsService()

				// 	var request = {
				// 		origin: origin,
				// 		destination: orderEntry.shippingAddress,
				// 		travelMode: "DRIVING",
				// 	}

				// 	// change later TODO
				// 	await directionsService.route(request, function (result, status) {
				// 		if (status == "OK") {
				// 			if (result && result.routes[0] && result.routes[0].legs[0]) {
				// 				const km = result.routes[0].legs[0].distance.text
				// 				const min = result.routes[0].legs[0].duration.text

				// 				orderEntry.distance = `${km}<br>(~ ${min.replace("Minuten", "min")})`
				// 			}
				// 		}
				// 	})
				// }
			})
			await fetchingPromise // important
		}
	}

	// store.orderData = sortOrderData(store.orderData)
	return store.orderData
}

function processProductTable(varId, productTable, product, orderEntry, variationQty = 1) {
	const store = Alpine.store("refueat")

	let allParts = JSON.parse(productTable.replaceAll(`\\\"`, `"`))
	for (const index in allParts) {
		const part = allParts[index]
		const id = varId
		const shorthand = part.product
		const size = part.size

		if (!store.nameIds[shorthand]) {
			// if the name occurred for the first time, we add the object for it and push the id
			store.nameIds[shorthand] = []
			store.nameIds[shorthand].push([id, size])
		} else {
			// if the object exists we add the id to the array, if it wasnt there yet
			// check for array in array of arrays hack
			let arr1 = JSON.stringify(store.nameIds[shorthand])
			let arr2 = JSON.stringify([id, size])
			if (arr1.indexOf(arr2) == -1) {
				store.nameIds[shorthand].push([id, size])
			}
		}
		if (!orderEntry.menus[shorthand]) {
			orderEntry.menus[shorthand] = {}
		}
		if (!orderEntry.menus[shorthand][size]) orderEntry.menus[shorthand][size] = 0
		// quantity consist of: amount of orders for this product * amount of sub-products within this product * amount of a single sub-product
		// example-order: 2x Catering für 50
		// amount of orders: 2x
		// amount of sub-products (pitabrot): 100x 1er Variation
		// amount of single sub-product: 1x in 1er Variation
		//      => 200x
		orderEntry.menus[shorthand][size] += parseInt(part.qty) * product.quantity * variationQty
	}
}

function setupProductsAndTotals(datesOrders) {
	const store = Alpine.store("refueat")

	// add orderQuantities in the the structure of
	// {
	// 	"7893": {
	// 	  "M": 	1,
	// 	  "XL": 2
	// 	}
	// }
	// to productQuantities per product

	for (const date in datesOrders) {
		const orders = datesOrders[date]
		if (!store.productQuantities[date]) {
			store.productQuantities[date] = {}
		}
		let dateQuantities = store.productQuantities[date]
		for (const order of orders) {
			for (const shorthand in order.menus) {
				const product = order.menus[shorthand]

				let found = shorthand in dateQuantities
				if (!found) {
					let p = {
						orders: {},
					}
					p.orders[order.orderNumber] = product
					dateQuantities[shorthand] = p
				} else {
					if (!dateQuantities[shorthand].orders[order.orderNumber])
						dateQuantities[shorthand].orders[order.orderNumber] = product
				}
			}
		}
	}

	// add totals object
	for (const date in datesOrders) {
		let dateQuantities = store.productQuantities[date]
		for (let shorthand in dateQuantities) {
			const product = dateQuantities[shorthand]
			product.totalQtys = {}
			for (const orderNumber in product.orders) {
				for (const size in product.orders[orderNumber]) {
					const sizeQty = product.orders[orderNumber][size]
					if (size in product.totalQtys) {
						product.totalQtys[size] += sizeQty
					} else {
						product.totalQtys[size] = sizeQty
					}
				}
			}
		}
	}

	// not needed?
	// sort into array so that each order is filled before next is listed (old sorting logic)
	// let arr = Object.entries(store.productQuantities)
	// let sortedArr = []
	// for (const order of store.orderData) {
	// 	let orderNo = order.orderNumber
	// 	let subArr = []
	// 	for (const [pShorthand, pEntry] of arr) {
	// 		if (Object.keys(pEntry.orders).includes(orderNo)) {
	// 			let product = [pShorthand, pEntry]
	// 			const found = sortedArr.find((e) => e[0] == pShorthand)
	// 			// only put it in the array if its not already inserted by a prior order
	// 			if (!found) {
	// 				subArr.push(product)
	// 			}
	// 		}
	// 	}
	// 	// sort entries per order alphabetically
	// 	subArr.sort()
	// 	sortedArr.push(...subArr)
	// }
	// store.sortedProductQuantities = sortedArr
}

function setupComponents() {
	const store = Alpine.store("refueat")

	// TODO: denote if vor ort or geliefert

	// everytime we call this function this gets setup again, so we need to reset it in order to not double all amounts
	store.dateComponents = {}
	store.components = []

	for (const day in store.productQuantities) {
		// skip when we are not on a date of the current daays of the week
		if (!store.daysOfTheWeek.map((a) => a[2]).includes(day)) continue
		const dayQuantities = store.productQuantities[day]
		if (!store.dateComponents[day]) store.dateComponents[day] = {}
		let dateComponents = store.dateComponents[day]
		for (const shorthand in dayQuantities) {
			const product = dayQuantities[shorthand]
			for (const size in product.totalQtys) {
				const productAmount = product.totalQtys[size]

				// get id to find appropriate components for this product
				const id = store.nameIds[shorthand].find((ni) => ni[1] == size)[0]

				// get components we stored
				const components = store.productComponents[id] ? store.productComponents[id].components : []

				// cycle through these components and add totals together
				for (const component of components) {
					let name = component.component

					if (!store.components.includes(name)) store.components.push(name)

					let unit = ""
					let unitAmount = 0
					if (component.qty) {
						unit = "Stk."
						unitAmount = Number(component.qty)
					} else if (component.grams) {
						unit = "g"
						unitAmount = Number(component.grams)
					} else if (component.liters) {
						unit = "l"
						unitAmount = Number(component.liters)
					}
					if (!dateComponents[name]) dateComponents[name] = {}

					if (!dateComponents[name][unit]) dateComponents[name][unit] = unitAmount * productAmount
					else dateComponents[name][unit] += unitAmount * productAmount

					if (dateComponents[name][unit] == 0) dateComponents[name][unit] = "ja"
				}
			}
		}
	}

	// setup component totals (only for visible week)
	store.componentTotals = {}
	for (const component of store.components) {
		for (const day in store.dateComponents) {
			if (store.daysOfTheWeek.map((d) => d[2]).indexOf(day) < 0) continue
			if (store.dateComponents[day][component]) {
				for (const unit in store.dateComponents[day][component]) {
					let amount = store.dateComponents[day][component][unit]
					if (!store.componentTotals[component]) store.componentTotals[component] = {}
					if (!store.componentTotals[component][unit]) store.componentTotals[component][unit] = amount
					else store.componentTotals[component][unit] += amount != "ja" ? amount : ""
				}
			}
		}
	}

	store.components = sortComponents(store.components)
}

/************* HELPERS ***************/

function getTimeFromOrder(order) {
	let time = ""
	if (order.delivery_type == "delivery") {
		time = order.meta_data[order.meta_data.findIndex((item) => item.key == "delivery_time")].value
	} else if (order.delivery_type == "pickup") {
		time = order.meta_data[order.meta_data.findIndex((item) => item.key == "pickup_time")].value
	}
	const regex = /^\d{1,2}:\d\d/gm

	let strippedTime = regex.exec(time)[0]

	if (strippedTime) {
		let hour = Number(strippedTime.substring(0, 2)) - 1
		let rest = strippedTime.substring(2, 5)
		hour = hour.toLocaleString("de", { minimumIntegerDigits: 2 })

		strippedTime = hour + rest
	}

	return strippedTime
}

function getDeliveryNameFromOrder(order) {
	if (order.delivery_type == "delivery") {
		return "Lieferung"
	} else if (order.delivery_type == "pickup") {
		return "Abholung"
	}
}

// dont think this is needed, if it is we need to rewrite logic
// function sortOrderData(orderData) {
// 	return orderData.sort(function (a, b) {
// 		if (a.time != b.time) return a.time.localeCompare(b.time)
// 		else return a.createdAt.localeCompare(b.createdAt)
// 	})
// }

function sortComponents(components) {
	return components.sort((a, b) => a > b)
}

let wcInterval

function wcRealtimeInit() {
	wcInterval = setInterval(() => {
		processOrders()
	}, 60000)
}
